import React from 'react'
// import PropTypes from 'prop-types'

import { Button, Flex, Box } from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Component: SendMessageButton
 *
 * Displays and controls the Send Message button
 */
class SendMessageButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }

    this.displaySendMessage = this.displaySendMessage.bind(this)
  }

  displaySendMessage () {
    this.props.displaySendMessage()
  }

  render () {
    return (
      <Flex>
        <Box
          sx={{
            zIndex: '10'
          }}
          // eslint-disable-next-line react/jsx-handler-names
          onClick={this.displaySendMessage}
        >
          <Button
            sx={{
              backgroundColor: 'transparent',
              fontSize: [3, 5],
              outline: 'transparent', // overrides an outline on :focus from rebass
              cursor: 'pointer',
              color: '#8cdff9',
              borderStyle: 'solid',
              borderColor: '#000',
              borderRadius: '50%',
              borderWidth: 1,
              width: '45px',
              height: '45px',
              padding: '0'
            }}
          >
            <FontAwesomeIcon icon={['fa', 'envelope']} />
          </Button>
          <Box
            sx={{
              backgroundColor: 'red',
              position: 'relative',
              right: '15px',
              top: '-20px',
              display: 'inline-block',
              borderRadius: '50%',
              borderStyle: 'solid',
              borderColor: '#000',
              borderWidth: '1px',
              padding: '2px',
              width: '20px',
              height: '20px',
              textAlign: 'center',
              fontSize: '14px',
              color: '#fff',
              lineHeight: '14px'
            }}
          >
            {this.props.unreadMessages}
          </Box>
        </Box>
      </Flex>
    )
  }
}

export default SendMessageButton
