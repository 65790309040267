//
// Component: DisplayGameCodes
//
// Displays & controls the scenario selection menu
//

// dependencies
import React from 'react'

import { observer } from 'mobx-react'
import { Box, Flex } from 'rebass'

// components
import Text from 'App/components/text/Text.js'
import H1 from 'App/components/text/H1.js'
import Input from 'App/components/form/Input.js'
import Select from 'App/components/form/Select.js'
import Button from 'App/components/form/Button.js'
import Label from 'App/components/form/Label.js'

@observer
class DisplayGameCodes extends React.Component {
  //
  // props
  //
  // None at the moment but expect them to be fed through from GameStore
  //

  constructor (props) {
    super(props)

    this.state = {
      joiningSession: false
    }

    this.joinSession = this.joinSession.bind(this)
    this.seatSelected = this.seatSelected.bind(this)
  }

  componentDidMount () {
    this.setState({
      error: false,
      errorMessage: '',
      playerName: null,
      joiningCode: this.props.gameCodes.northCode,
      gameCodes: {
        northCode: null,
        eastCode: null,
        southCode: null,
        westCode: null
      },
      seat: {
        player: null,
        code: null
      },
      playerSession: {
        player: null,
        token: null
      },
      joiningSession: false
    })
  }

  /**
   * FUNCTION: joinSession
   *
   * Join a game
   */

  async joinSession () {
    if (!this.state.playerName) {
      this.setState({
        error: true,
        errorMessage: 'Please select a game code and enter your name'
      })
    } else {
      this.playerSession = await this.props.joinSession(this.state.joiningCode, this.state.playerName)

      if (this.playerSession && this.playerSession.error) {
        this.setState({
          error: true,
          errorMessage: this.playerSession.error
        })
      }
    }
  }

  /**
   *
   * seatSelected
   *
   * User has selected a seat to play at
   */
  seatSelected (data) {
    this.setState({
      joiningCode: data.currentTarget.value
    })
  }

  render () {
    if (!this.props.gameCodes?.northCode) {
      return (<Box>Unabled to load game codes</Box>)
    }

    return (
      <>
        <Flex
          sx={{
            fontSize: [2],
            flexDirection: 'column'
          }}
        >
          <Box width={1}>
            <Label htmlFor='seat'>Your Seat</Label>
          </Box>
          <Box width={1}>
            {/* eslint-disable-next-line react/jsx-handler-names */}
            <Select id='seat' name='seat' defaultValue='North' onChange={this.seatSelected}>
              {this.props.gameCodes.northCode && <option value={this.props.gameCodes.northCode}>North</option>}
              {this.props.gameCodes.eastCode && <option value={this.props.gameCodes.eastCode}>East</option>}
              {this.props.gameCodes.southCode && <option value={this.props.gameCodes.southCode}>South</option>}
              {this.props.gameCodes.westCode && <option value={this.props.gameCodes.westCode}>West</option>}
            </Select>
          </Box>
          <Box width={1}>
            <Label mt={1} htmlFor='player-name'>Your Name</Label>
          </Box>
          <Box width={1}>
            <Input id='player-name' name='player-name' placeholder='Your Name' maxLength='10' onChange={(input) => { this.setState({ playerName: input, error: false }) }} />
          </Box>

          <Box width={1} mt={3} mb={3}>
            {(this.props.gameCodes.eastCode || this.props.gameCodes.southCode || this.props.gameCodes.westCode) && <H1>Game codes to share:</H1>}
            <Box width={1} mt={3} mb={3}>
              {this.props.gameCodes.northCode && this.state.joiningCode !== this.props.gameCodes.northCode && <Text>North: <strong>{this.props.gameCodes.northCode}</strong></Text>}
              {this.props.gameCodes.eastCode && this.state.joiningCode !== this.props.gameCodes.eastCode && <Text>East:  <strong>{this.props.gameCodes.eastCode}</strong></Text>}
              {this.props.gameCodes.southCode && this.state.joiningCode !== this.props.gameCodes.southCode && <Text>South: <strong>{this.props.gameCodes.southCode}</strong></Text>}
              {this.props.gameCodes.westCode && this.state.joiningCode !== this.props.gameCodes.westCode && <Text>West:  <strong>{this.props.gameCodes.westCode}</strong></Text>}
            </Box>
          </Box>

          <Box width={1}>
            <Button
              mt={1}
              sx={{
                textTransform: 'uppercase',
                backgroundColor: '#46282C',
                borderRadius: 0,
                cursor: 'pointer'
              }}
              // eslint-disable-next-line react/jsx-handler-names
              onClick={this.joinSession}
            >
              Join Game
            </Button>
          </Box>
        </Flex>

        {/* Error messages */}
        {this.state.error &&
          <Flex
            sx={{
              color: 'red',
              fontSize: [2]
            }}
          >
            {this.state.errorMessage}
          </Flex>}
      </>
    )
  }
}
export default DisplayGameCodes
