//
// Store: GameStore
//
// The guts of the app. Controls everything.
//

import { observable } from 'mobx'

import React from 'react'
// import { mobxDidRunLazyInitializersSymbol } from 'mobx/lib/internal'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ApiService from 'App/services/ApiService'

// @observer
class GameStore extends React.Component {
  // constructor (props) {
  // super(props)
  // }

  // checkForBotsTurn = autorun(() => {
  //   if (this.turn === 'east' || this.turn === 'west') {
  //     console.log('bot to play ', this.turn)
  //     this.botPlay(this.turn)
  //   }
  // })

  // This should normally be 13 but set
  // to 1 for testing end game scenario
  tricksInGame = 13

  userToken = null // user token to identify individual user

  // systemOverride set to true will
  // set all hands to visible
  @observable systemOverride = {
    north: false,
    south: false,
    east: false,
    west: false
  }

  @observable error = false // Has there been an error ?
  @observable errorMessage = '' // Error message to be displayed
  @observable errorMessageSeen = false

  // Current player's turn
  @observable turn // north, south, east, west, system
  @observable turnNumber = 1 // player turn ie. 1-4
  @observable trickNumber = 1 // tricks ie. 1-13

  @observable phase = '' // setup, auction, play, results
  @observable declarer = 'south'
  @observable dummy = ''
  @observable leadCardSuit = ''

  // Claim game has been requested and by player
  @observable claimGameRequested = false
  @observable claimRequestedBy = null

  // Flag to determine if player needs to approve a Claim request
  @observable approveClaimRequest = false
  @observable claimRequestedBy = ''
  // @observable claimVisibility = {
  //   north: false,
  //   south: false,
  //   east: false,
  //   west: false
  // }

  // The scenario number selected by the user
  @observable selectedScenario = null

  // All filtered scenarios
  // eg. all Beginners scenarios from DB
  allScenarios = []

  // Game Key identifier from start Game
  gameKey = ''

  // Individual game player session details
  @observable playerSession = {
    player: null,
    code: null,
    token: null
  }

  @observable primaryPlayer = false // Is the primary player ie. the one who starts the game

  // Player timeout if they haven't played after 30 seconds
  playerTimeout = null
  @observable playerTimedOut = false // Display prompt if true to take turn
  allowTimeout = true
  @observable partnerTimedOut = false
  partnerTimer // container for partner timer
  continueWaitingForPartnerFlag = false

  // Overall session details
  @observable sessionStatus = {}

  @observable allPlayersJoined = false // Flag to show when all four players have joined
  @observable allPlayers = { // Names of all players
    north: '',
    east: '',
    south: '',
    west: ''
  }

  @observable bidTurn // Whose turn is it to bid ?

  @observable bid = {}
  @observable bidRound = null // Which round of bidding is it ? 1, 2, 3 etc
  bidCount = 0 // Count of bids so we know when we have completed one round
  bidPassCount = 0 // Count of consecutive passes
  @observable bidError = '' // Holds any error made during bidding
  @observable displayBidHistory = false // At the end of the bidding display the bid history
  @observable displayBidHistorySeen = false // Display Bid History has been displayed

  @observable seats // Seat names north, south, east, west - these will rotate to make the current player in the south position
  @observable bidInterval = null // container for the bid interval
  waitForAllPlayersInterval = null // container for the wait for all players interval
  @observable southBid = false // Is it South's turn to bid ?

  @observable playerName = ['south', 'west', 'north', 'east'] // Player positions starting in South position going clockwise
  @observable playerRealName = [null, null, null, null] // Player names starting in South position going clockwise
  @observable messages // Holder for display of messages sent to players
  readMessages = 0 // Number of messages read
  @observable unreadMessages // Number of messages unread
  firstRead = true // If user refreshes then we can use this flag to assume all messages have been read
  // Stores the current round of bids
  // for display on the table
  @observable roundBids = {
    north: {
      show: false,
      bids: [{
        bidValue: null,
        bidSuit: '',
        pass: false,
        reason: ''
      }]
    },
    east: {
      show: false,
      bids: [{
        bidValue: null,
        bidSuit: '',
        pass: false,
        reason: ''
      }]
    },
    south: {
      show: false,
      bids: [{
        bidValue: null,
        bidSuit: '',
        pass: false,
        reason: '',
        options: {},
        validOption: null,
        showBid: false
      }]
    },
    west: {
      show: false,
      bids: [{
        bidValue: null,
        bidSuit: '',
        pass: false,
        reason: ''
      }]
    }
  }

  @observable board = {}

  @observable hands = {
    north: {},
    east: {},
    south: {},
    west: {}
  }

  @observable singleSuitHands = { // Player has clicked on hand and only cards of that suit are displayed
    north: '',
    east: '',
    south: '',
    west: ''
  }

  @observable cards = {
    north: [],
    east: [],
    south: [],
    west: []
  }

  // Cards played to the Table
  @observable table = {
    north: {
      rank: '',
      suit: ''
    },
    east: {
      rank: '',
      suit: ''
    },
    south: {
      rank: '',
      suit: ''
    },
    west: {
      rank: '',
      suit: ''
    }
  }

  // Array of players that won each trick
  @observable tricksWon = []

  // Counts of tricks won by partners
  @observable tricksWonByPartners = {
    northsouth: 0,
    eastwest: 0
  }

  @observable score = null
  @observable previousScore = null

  // Set to true after 13 tricks played
  @observable gameOver = false

  // Flag to determine if player needs to approve an Undo request
  @observable approveUndoRequest = false
  @observable undoRequestedBy = ''

  // Scenario's that will eventually be accessed by API
  //
  // In PBN notation
  //
  // N indicates start deal player (I think)
  //
  // Dot separates suits, in the order SHDC
  // Space separates players hands
  // @observable scenario = [
  //   // 'N:A.QJ.. Q..K4. .A.9.J ..J6.9',
  //   'N:A843.QJ6.K.A6542 KQ9752.K74.8742. T.A93.QT93.KJ873 J6.T852.AJ65.QT9',
  //   'N:K843.QJ6.K.A6542 AQ9752.K74.8742. J.A93.QT93.KJ873 T6.T852.AJ65.QT9'
  // ]

  // Some useful 'global' lookup variables
  suitOrder = {
    H: 0,
    S: 1,
    D: 2,
    C: 3
  }

  bidSuitOrder = {
    NT: 0,
    S: 1,
    H: 2,
    D: 3,
    C: 4
  }

  seatOrder = {
    S: 0,
    W: 1,
    N: 2,
    E: 3
  }

  suitName = {
    S: 'spades',
    H: 'hearts',
    D: 'diamonds',
    C: 'clubs'
  }

  suitLetter = {
    spades: 'S',
    hearts: 'H',
    diamonds: 'D',
    clubs: 'C',
    notrumps: 'NT'
  }

  // Get next person to bid
  playerBidOrder = {
    west: 'north',
    north: 'east',
    east: 'south',
    south: 'west'
  }

  //
  // FUNCTION: initialise
  //
  // Initialises gameStore variables

  initialise = function () {
    this.winner = null
    this.tricksWonByPartners.northsouth = 0
    this.tricksWonByPartners.eastwest = 0
    this.gameOver = false
    this.tricksWon = []
    this.allPlayersJoined = false
    this.systemOverride = false

    // This was uncommented but crashes
    // But why was it in ? Called from initDeal which is in getSessionStatus which then reads this.sessionStatus !
    // this.sessionStatus = {}

    this.table = {
      north: {
        rank: '',
        suit: ''
      },
      east: {
        rank: '',
        suit: ''
      },
      south: {
        rank: '',
        suit: ''
      },
      west: {
        rank: '',
        suit: ''
      }
    }

    this.singleSuitHands = {
      north: '',
      east: '',
      south: '',
      west: ''
    }

    this.roundBids = {
      north: {
        show: false,
        bids: [{
          bidValue: null,
          bidSuit: '',
          pass: false,
          reason: ''
        }]
      },
      east: {
        show: false,
        bids: [{
          bidValue: null,
          bidSuit: '',
          pass: false,
          reason: ''
        }]
      },
      south: {
        show: false,
        bids: [{
          bidValue: null,
          bidSuit: '',
          pass: false,
          reason: '',
          options: {},
          validOption: null,
          showBid: false
        }]
      },
      west: {
        show: false,
        bids: [{
          bidValue: null,
          bidSuit: '',
          pass: false,
          reason: ''
        }]
      }
    }
  }

  /** FUNCTION: setPhase
   *
   *  Sets the phase of the game
   *
   *  INPUT: phase - 'auction', 'play'
   */

  setPhase = function (phase) {
    this.phase = phase
  }

  /** FUNCTION: setBid
   *
   *  Sets the current player's bid
   *
   *  INPUT: bid object
   *
   *  playerBid = {
   *   player: west, north, south, east,
   *   bidValue: 1-10,
   *   bidSuit: spade, heart, club, diamond,
   *   pass: Bool to show if player has passed
   *  }

   */

  setBid = function (playerBid) {
    this.playerBid[playerBid.player] = {
      bidValue: playerBid.bidValue,
      bidSuit: playerBid.bidSuit,
      pass: playerBid.pass
    }
  }

  /**
   * FUNCTION: initDeal
   *
   * Sets up the Board based on the given scenario ie. deals four hands
   * Sets up the Game
   *
   * INPUT: scenario - unique number to reference a given scenario
   */
  initDeal = async function (scenario) {
    this.initialise()

    var url = '/api/v1/game/start'

    var params = {}

    var options = {
      scenario: scenario,
      playerId: this.userToken
    }

    try {
      var gameKey = await ApiService.post(url, params, options)
    } catch (err) {
      console.log(err)
    }

    this.gameKey = gameKey.gameKey
    this.dealHands(scenario)
  }

  /**
   * FUNCTION: dealHands
   *
   * Sets up the Game
   *
   * INPUT: scenario - unique number to reference a given scenario
   */
  dealHands = function (scenario) {
    var board = new global.bridge.Board()
    board.setNumber(scenario) // Do we need this ? What does it do ?

    // convertToPBN requires an array
    // so for liveplay convert to array
    // but will always only be length of 1
    var scenarios = []
    scenarios[0] = this.sessionStatus
    scenarios[0].id = 1
    // scenarios[0].opener = 'west'
    this.selectedScenario = 1
    this.convertToPBN(scenarios)

    board.deal(this.scenarios.find(o => o.id === parseInt(scenario)).hand)
    this.board = board

    this.phase = 'deal'

    this.rotatePlayers()

    // PBN - Portable Bridge Notation
    // We use this notation to define the hands
    //
    // eg. 'N:A843.QJ6.K.A6542 KQ9752.K74.8742. T.A93.QT93.KJ873 J6.T852.AJ65.QT9'
    //
    // - Deal from North
    // - Cards in order SHDC separated by full stops
    // - Hands in order NESW separated by space
    this.hands.south = board.hands[global.bridge.seat[this.seats[0]]].toPBN()
    this.hands.west = board.hands[global.bridge.seat[this.seats[1]]].toPBN()
    this.hands.north = board.hands[global.bridge.seat[this.seats[2]]].toPBN()
    this.hands.east = board.hands[global.bridge.seat[this.seats[3]]].toPBN()

    this.phase = this.sessionStatus.phase
  }

  /**
   * FUNCTION: arrayRotate
   *
   * Rotates an array arr count times
   */
  arrayRotate = function (arr, count) {
    for (var i = 0; i < count; i++) {
      arr.push(arr.shift())
    }

    return arr
  }

  /**
   * FUNCTION: setupHand
   */
  setupHand = function (declarer) {
    if (this.game === undefined) {
      var game = new global.bridge.Game()

      this.game = game
    }

    // Set the bid suit and value in
    // storage in the game object
    this.game.contract.level = this.bid.level

    this.game.contract.denomination = this.bid.denomination

    this.game.contract.declaror = declarer

    // Dummy position needs rotating to position they are sitting
    const seatPosition = {
      0: 'south',
      1: 'west',
      2: 'north',
      3: 'east'
    }

    var playerKey = Object.keys(this.seats).find(key => this.seats[key] === global.bridge.seat[declarer].partner.name)

    this.dummy = seatPosition[playerKey]

    this.singleSuitDisplayed = false
    this.leadCardSuit = ''
  }

  botPlay = async function (player) {
    // It sometimes throws a wobbler
    // so ensure values are set correctly
    if (player !== this.turn) {
      return null
    }

    var url = '/api/v1/game/getbestmove'

    var params = {}

    var apiTricks = await this.convertTricks(this.game.tricks)
    console.log('Tricks played ', apiTricks)

    console.log('Player playing is ', player)
    console.log('This.turn is ', this.turn)

    var hands = {
      north: this.hands.north,
      south: this.hands.south,
      east: this.hands.east,
      west: this.hands.west
    }

    var options = {
      scenario: this.selectedScenario,
      hands: hands,
      player: this.turn,
      tricks: apiTricks
    }

    try {
      var botCardToPlay = await ApiService.post(url, params, options)
    } catch (err) {
      console.log(err)
    }

    console.log('Bots turn ' + JSON.stringify(botCardToPlay.bestMove))

    setTimeout(function () {
      this.playCard(this.turn, botCardToPlay.bestMove.rank, this.suitLetter[botCardToPlay.bestMove.suit])
    }.bind(this),
    2000)
  }

  //
  // FUNCTION: validateCard
  //
  // Check that a valid card has been played
  // If a player has a card of the suit that
  // led this trick then it must be played
  //
  // INPUT: player - north, south, east or west
  //        suit   - S, H, D, C
  //
  validateCard = function (player, suit) {
    // Is this the same suit as the lead suit ?
    // ok to play
    if (suit === this.leadCardSuit) {
      return true
    }

    // Do we have any cards of the lead suit ?
    if (this.board.hands[player].cardsWithSuit(this.leadCardSuit).length > 0) {
      return false
    } else {
      return true
    }
  }

  //
  // FUNCTION: playCard
  //
  // Moves card on to the table
  // Removes card from player's hand
  // Plays card in the Game
  // Logs card played to the DB
  // Sets up next player's turn
  // Determines winner of trick if necessary
  //
  // INPUT: playerPosition - north, south, east or west - this is their position on the table
  //                         not their actual position
  //        rank   - card value
  //        suit   - S, H, D, C
  //
  // RETURN: Null or error message

  playCard = async function (playerPosition = 'south', rank, suit) {
    console.log('card played is ', playerPosition + rank + suit)

    // Clear waiting timeout if set
    clearTimeout(this.playerTimeout)

    // Convert position to actual player
    const seatPosition = {
      south: 0,
      west: 1,
      north: 2,
      east: 3
    }

    var player = this.seats[seatPosition[playerPosition]]

    // If it's not this player's turn do nothing
    if (player !== this.turn) {
      return null
    }

    // If player is a bot can skip this bit
    if (playerPosition === 'south' || playerPosition === 'north') {
      if (this.singleSuitHands[player].length === 0) {
        // We've clicked on our hand so
        // just display cards of that suit
        this.singleSuitHands[player] = this.getSingleSuitCards(playerPosition, suit)
        return
      }
    }

    var playCardResult = await this.sendCard(this.suitName[suit], rank)

    if (playCardResult && playCardResult.error) {
      return playCardResult
    }

    // Reset errorMessageSeen so that at the
    // end of the trick the message will be seen
    this.errorMessageSeen = false

    // Revert the hand to display all cards
    this.singleSuitHands[player] = ''

    // Move card to table view
    var moveLogged = this.logMove(player, rank, suit)

    console.log('Move logged ', moveLogged)

    this.tableClear = false

    // force a refresh of the table
    this.getSessionStatus()

    // If we've had four cards played then determine winner
    if (++this.turnNumber > 3) { // Was 4 !
      this.winner = this.turn
      console.log('trick winner is ', this.winner)
      this.turn = null

      // Set timeout before clearing the Table
      // and checking to see if game is over
      // setTimeout(() => {
      //   this.table = {
      //     north: {
      //       rank: '',
      //       suit: ''
      //     },
      //     east: {
      //       rank: '',
      //       suit: ''
      //     },
      //     south: {
      //       rank: '',
      //       suit: ''
      //     },
      //     west: {
      //       rank: '',
      //       suit: ''
      //     }
      //   }
      // },
      // 2000)
    }
  }

  /**
   * FUNCTION: sendCard
   *
   * Sends card to DB
   */
  sendCard = async function (suit, rank) {
    var url = '/api/v1/session/play'

    var params = {
      token: this.playerSession.token,
      suit: suit,
      rank: rank
    }

    var options = {}

    try {
      var cardPlayed = await ApiService.post(url, params, options)
    } catch (err) {
      console.log(err)
    }

    console.log('Card played ', cardPlayed)

    return cardPlayed
  }

  /**
   * FUNCTION: checkForBotToPlay
   *
   * Check and play bots turn
   */
  checkForBotToPlay = function () {
    if (this.turn === 'east' || this.turn === 'west') {
      console.log('bot to play ', this.turn)
      this.botPlay(this.turn)
    }
  }

  /**
   * FUNCTION: removeCardFromHand
   *
   * Removes given card from given player's hand
   * in this.hands
   *
   * INPUT: player     - north, south, east or west
   *        searchRank - card value
   *        searchSuit - S, H, D, C
   */

  removeCardFromHand = function (player, searchRank, searchSuit) {
    var hand = this.hands[player]

    var startSearchFrom = this.getPosition(hand, '.', this.suitOrder[searchSuit])

    var cardIndexToRemove = hand.indexOf(searchRank, startSearchFrom)
    var beforeSearch = hand.substring(0, cardIndexToRemove)
    var afterSearch = hand.substring(cardIndexToRemove + 1, hand.length)

    hand = beforeSearch + afterSearch
    this.hands[player] = hand

    this.removeCardFromBoardHand(player, searchRank, searchSuit)
  }

  //
  // FUNCTION: getPosition
  //
  // Returns n'th occurence of character m in string str
  //

  getPosition = function (str, m, n) {
    return str.split(m, n).join(m).length
  }

  //
  // FUNCTION: removeCardFromBoardHand
  //
  // Removes given card from given player's hand
  // in this.board.hands.south.cards
  // bridge.js doesn't do this so we will have to
  //
  // INPUT: player     - north, south, east or west
  //        searchRank - card value
  //        searchSuit - S, H, D, C
  //

  removeCardFromBoardHand = function (player, searchRank, searchSuit) {
    var hand = this.board.hands[player].cards

    var index
    for (let i = 0; i < hand.length; i++) {
      if (hand[i].rank === searchRank && hand[i].suit === searchSuit) {
        index = i
        break
      }
    }

    this.board.hands[player].cards.splice(index, 1)
  }

  //
  // FUNCTION: getSingleSuitCards
  //
  // Builds a PBN hand of the selected suit
  // from the player's hand
  //
  // INPUT: player     - north, south, east or west
  //        searchSuit - S, H, D, C
  //

  getSingleSuitCards = function (player, searchSuit) {
    // BridgeJS function to do this:
    // this.board.hands['south'].cardsWithSuit('D')
    // but then how do we put it in PBN to display ?

    // var hand = this.board.hands[player].cards

    // var filteredHand = hand.filter(function (card) {
    //   return card.suit === searchSuit
    // })

    // return filteredHand

    var firstCardOfSuit
    var firstCardOfNextSuit
    var searchFrom

    var hand = this.hands[player]

    firstCardOfSuit = this.getPosition(hand, '.', this.suitOrder[searchSuit])

    if (searchSuit === 'C') {
      firstCardOfNextSuit = hand.length
    } else {
      firstCardOfNextSuit = this.getPosition(hand, '.', this.suitOrder[searchSuit] + 1)
    }

    if (searchSuit === 'H') {
      searchFrom = firstCardOfSuit
    } else {
      searchFrom = firstCardOfSuit + 1
    }
    var singleHand = hand.substring(searchFrom, firstCardOfNextSuit)

    // Now add the appropriate number of dots
    // to the start so we get the PBN in the right format
    var dot = '.'
    return dot.repeat(this.suitOrder[searchSuit]) + singleHand
  }

  /**
   * FUNCTION: getBidHistory
   *
   * Returns an HTML table of the bids played
   */

  getBidHistory = function () {
    if (this.phase === 'setup') {
      return null
    } else {
      return this.sessionStatus.bids
    }
  }

  /**
   * FUNCTION: getTrickHistory
   *
   * Returns an HTML table of the tricks played
   */

  getTrickHistory = function () {
    if ((this.phase === 'play' || this.phase === 'results') && this.sessionStatus.plays.length > 0) {
      return this.sessionStatus.plays
    } else {
      return 'No tricks played yet'
    }
  }

  /**
   * FUNCTION: getBid
   *
   * Returns the player's bid
   *
   * INPUT: player - north, east, south, west
   *        bidRound - integer
   */
  getBid = function (player, bidRound) {
    var scenarioIndex = this.scenarios.findIndex(x => x.id === parseInt(this.selectedScenario))

    if (this.scenarios[scenarioIndex].bids.length < bidRound) {
      return null
    }

    var bid = this.scenarios[scenarioIndex].bids[bidRound - 1]

    return bid[player]
  }

  /**
   * FUNCTION: getScenarios
   *
   * Call the APIService to get the scenarios from the DB
   * using the difficulty and title passed through
   */

   getScenarios = async function (difficulty, title, limit, page) {
     var params = {
       fields: 'id,declarer,dummy,player,handNorthClubs,handNorthSpades,handNorthDiamonds,handNorthHearts,handEastClubs,handEastSpades,handEastDiamonds,handEastHearts,handSouthClubs,handSouthSpades,handSouthDiamonds,handSouthHearts,handWestClubs,handWestSpades,handWestDiamonds,handWestHearts,bids,winningBidSuit,winningBidValue,trump,title,description,difficulty,opener,solution',
       limit: limit,
       where: { declarer: 'south' },
       page: page
     }

     if (difficulty !== 'All') {
       params.where.difficulty = difficulty.toLowerCase()
     }

     if (title) {
       // NOTE: how do we do LIKE ?
       params.where.title = title
     }

     var url = '/api/scenario'

     try {
       var scenarios = await ApiService.get(url, params)
     } catch (err) {
       console.log(err)
     }

     this.allScenarios = scenarios
     return scenarios
   }

   /**
    * FUNCTION: convertToPBN
    *
    * Data retrieved from the DB into this.scenarios
    * is converted into PBN notation
    *
    * Each row in DB has for example:
    *   handEastDiamond: 'A56T',
    *   handEastClubs: 'KQJ'
    *  etc.
    *
    * PBN notation is
    *   'N:A843.QJ6.K.A6542 KQ9752.K74.8742. T.A93.QT93.KJ873 J6.T852.AJ65.QT9'
    */
   convertToPBN = function (scenarios) {
     var PBNScenarios = []
     var PBNString
     var PBNObject = {}

     scenarios.forEach(function (s) {
       //  PBNString = 'N:' + s.handNorthSpades + '.' + s.handNorthHearts + '.' + s.handNorthDiamonds + '.' + s.handNorthClubs + ' ' +
       //   s.handEastSpades + '.' + s.handEastHearts + '.' + s.handEastDiamonds + '.' + s.handEastClubs + ' ' +
       //   s.handSouthSpades + '.' + s.handSouthHearts + '.' + s.handSouthDiamonds + '.' + s.handSouthClubs + ' ' +
       //   s.handWestSpades + '.' + s.handWestHearts + '.' + s.handWestDiamonds + '.' + s.handWestClubs
       PBNString = 'N:' + s.handNorthHearts + '.' + s.handNorthSpades + '.' + s.handNorthDiamonds + '.' + s.handNorthClubs + ' ' +
       s.handEastHearts + '.' + s.handEastSpades + '.' + s.handEastDiamonds + '.' + s.handEastClubs + ' ' +
       s.handSouthHearts + '.' + s.handSouthSpades + '.' + s.handSouthDiamonds + '.' + s.handSouthClubs + ' ' +
       s.handWestHearts + '.' + s.handWestSpades + '.' + s.handWestDiamonds + '.' + s.handWestClubs
       PBNString = PBNString.replace(/undefined/g, '')
       PBNObject = {
         id: s.id,
         declarer: s.declarer,
         dummy: s.dummy,
         player: s.player,
         hand: PBNString,
         bids: s.bids,
         opener: s.opener
       }
       PBNScenarios.push(PBNObject)
     })

     this.scenarios = PBNScenarios
     return this.scenarios
   }

   /**
    * FUNCTION: initialiseBids
    */
   initialiseBids = function () {
     this.roundBids.north.show = null
     this.roundBids.east.show = null
     this.roundBids.south.show = null
     this.roundBids.west.show = null
   }

   /**
    * FUNCTION: setScenario
    *
    * Sets the scenario number that the user has selected
    */
   setScenario = function (selectedScenario) {
     this.selectedScenario = selectedScenario
   }

   /**
    * FUNCTION: convertTricks
    *
    * Convert tricks from this.game.tricks to
    * format for getbestmove API
    */
   convertTricks = async function (gameTricks) {
     var apiTricks = []
     //  var trickCounter = 0

     gameTricks.forEach(function (trick) {
       var trickArray = []
       trick.play.forEach(function (play) {
         var thisTrick = {}
         thisTrick.player = play.seat.name
         thisTrick.suit = this.suitName[play.card.suit]
         thisTrick.rank = play.card.rank
         trickArray.push(thisTrick)
       }, this)
       apiTricks.push(trickArray)
     }, this)

     return apiTricks
   }

   /**
    * FUNCTION: logMove
    *
    * Log current move to DB
    */
   logMove = async function (player, rank, suit) {
     //  Log move on DB
     var url = '/api/v1/game/logmove'

     var hands = {}

     hands[this.seats[0]] = this.hands.south
     hands[this.seats[1]] = this.hands.west
     hands[this.seats[2]] = this.hands.north
     hands[this.seats[3]] = this.hands.east

     var params = {}

     var options = {
       gameKey: this.gameKey,
       hands: hands,
       trickNumber: this.trickNumber,
       turnNumber: this.turnNumber,
       player: player,
       playedSuit: this.suitName[suit],
       playedRank: rank
     }

     try {
       var moveLogged = await ApiService.post(url, params, options)
     } catch (err) {
       console.log(err)
     }

     return moveLogged
   }

   /**
    * FUNCTION: recordGameEnd
    *
    * Log current move to DB
    */
   recordGameEnd = async function () {
     var url = '/api/v1/game/end'

     var params = {}

     var gameWon
     if (this.tricksWonByPartners.northsouth > this.tricksWonByPartners.eastwest) {
       gameWon = true
     } else {
       gameWon = false
     }

     var options = {
       gameKey: this.gameKey,
       gameWon: gameWon,
       score: this.score
     }

     try {
       var gameEnd = await ApiService.post(url, params, options)
     } catch (err) {
       console.log(err)
     }

     return gameEnd
   }

   /**
    * Clears the single suit being shown
    * This will only be available for north
    * or south seated players but we need
    * to work out which hand they actually are
    */
   resetSingleSuitHand = function (player) {
     var actualPlayerName

     switch (player) {
       case 'north':
         actualPlayerName = this.seats[2]
         break
       case 'south':
         actualPlayerName = this.seats[0]
         break
     }

     this.singleSuitHands[actualPlayerName] = []
   }

   /**
    * Sets all hands to be visible
    */
   makeHandsVisibleAfterClaim = function (player, requestedBy) {
     this.systemOverride = {
       north: false,
       south: true,
       east: true,
       west: true
     }
     // if ((player === 'north' || player === 'south') && (requestedBy === 'east' || requestedBy === 'west')) {
     //      // NOTE: systemOverride points to compass position not player value
     //      this.systemOverride = {
     //        north: player === 'north',
     //        south: player === 'south',
     //        east: true,
     //        west: true
     //      }
     //    }

     //    if ((player === 'east' || player === 'west') && (requestedBy === 'north' || requestedBy === 'south')) {
     //      this.systemOverride = {
     //        north: false,
     //        south: true,
     //        east: true,
     //        west: true
     //      }
     //    }
     // this.systemOverride = true
   }

   /**
    * Calculates the score
    */
   scoreGame = function () {
     let denomination
     let vulnerability = true

     if (this.sessionStatus.vulnerability === 3) {
       vulnerability = false
     }

     if (this.sessionStatus.winningBidSuit === 'notrump') {
       denomination = 'NT'
     } else {
       denomination = this.capitalizeFirstLetter(this.sessionStatus.winningBidSuit).charAt(0)
     }
     var contract = {
       level: parseInt(this.sessionStatus.winningBidValue),
       denomination: denomination,
       risk: ''
     }

     let tricksMade

     if (this.sessionStatus.declarer === 'east' || this.sessionStatus.declarer === 'west') {
       if (this.sessionStatus.vulnerability === 2) {
         vulnerability = true
       }
       tricksMade = this.sessionStatus.EWTricks
     } else {
       if (this.sessionStatus.vulnerability === 1) {
         vulnerability = true
       }
       tricksMade = this.sessionStatus.NSTricks
     }
     var score = global.scorer.contractTricks(contract, vulnerability, tricksMade)
     //  var score = 0

     //  // Assume no doubles or vulnerability for now
     //  var contractPoints = {
     //    NT: 40,
     //    S: 30,
     //    H: 30,
     //    D: 20,
     //    C: 20
     //  }

     //  var overtrickPoints = {
     //    NT: 30,
     //    S: 30,
     //    H: 30,
     //    D: 20,
     //    C: 20
     //  }

     //  var penaltyPoints = 50

     //  // Expected tricks
     //  var expectedTricks = 6 + parseInt(this.bid.level)

     //  // How many tricks over or above the contract have they won?
     //  var trickBalance = this.tricksWonByPartners.northsouth - expectedTricks

     //  if (trickBalance >= 0) {
     //    if (this.bid.denomination === 'NT') {
     //      //  notrumps score 40 for first trick but only 30 for any further tricks
     //      score = contractPoints[this.bid.denomination]
     //      if (parseInt(this.bid.level) > 1) {
     //        score += (contractPoints[this.bid.denomination] - 10) * (parseInt(this.bid.level) - 1)
     //      }
     //      score += overtrickPoints[this.bid.denomination] * trickBalance
     //    } else {
     //      score = contractPoints[this.bid.denomination] * parseInt(this.bid.level)
     //      score += overtrickPoints[this.bid.denomination] * trickBalance
     //    }
     //  } else {
     //    //  Lost the contract
     //    score = penaltyPoints * trickBalance
     //  }

     return score
   }

   /**
    * Concede game button has been pressed
    *
    * Add remaining tricks to west/east and
    * finish the game
    */
   concedeGame = function () {
     this.tricksWonByPartners.eastwest += 13 - (this.tricksWonByPartners.eastwest + this.tricksWonByPartners.northsouth)
     this.endGame()

     console.log('Game conceded')
   }

   /**
    * Game has ended so reset some stuff
    */
   endGame = async function () {
     this.systemOverride = false
     this.turn = ''

     this.score = this.scoreGame()

     var gameEnd = await this.recordGameEnd()
     console.log('Game End ', gameEnd)

     this.gameOver = true
   }

   /**
    * Store user token
    */
   storeUserToken = function (userToken) {
     this.userToken = userToken
   }

   /**
    * Get last result for this user
    * for this scenario
    */
   getLastResult = async function () {
     var url = '/api/v1/game/getlastresult'

     var params = {}

     var options = {
       scenario: this.selectedScenario,
       playerId: this.userToken
     }

     try {
       var gameHistory = await ApiService.post(url, params, options)
     } catch (err) {
       console.log(err)
     }

     return gameHistory
   }

   /**
    * Store the bid
    */
   storeBid = async function (player, rank, suit, pass, double, redouble) {
     var url = '/api/v1/session/bid'

     var params = {
       token: this.playerSession.token
     }

     if (pass) {
       params.pass = true
     } else if (double) {
       params.double = true
     } else if (redouble) {
       params.redouble = true
     } else {
       params.suit = suit === 'nt' ? 'notrump' : suit
       params.value = rank
     }

     var options = {}

     try {
       var bidMade = await ApiService.post(url, params, options)
     } catch (err) {
       console.log(err)
     }

     if (bidMade.error) {
       this.bidError = bidMade.error
     } else {
       this.bidError = ''

       console.log('Bid made is ', bidMade)

       // Store bid for display in navbar
       if (!pass && !double && !redouble) {
         this.bid.denomination = suit
         this.bid.level = rank
       }

       //  Store bid locally
       this.roundBids[this.playerSession.player].show = true

       // Initialise bidArray
       var bidArray = {
         bidValue: rank,
         bidSuit: suit,
         pass: pass,
         double: double,
         redouble: redouble,
         reason: ''
       }

       // Update the bid count and see if
       // we should start a new round of bids ?
       //  PROBABLY DON'T NEED THIS
       this.bidCount++
       if (this.bidCount > 4) {
         this.bidCount = 1
         this.bidRound++
       }

       this.roundBids[this.playerSession.player].bids[this.bidRound - 1] = bidArray
     }
   }

   /**
    * Start a new session
    *
    * Returns the game codes needed to join the game
    */
   startSession = async function (scenario, gameType = 'full', numHumans = 'four') {
     //  this.phase = 'setup'
     //  this.setScenario(scenario)
     var url = '/api/v1/session/create'

     var params = {}

     var options = {
       gameType,
       numHumans
     }

     // If we have a scenario then post that
     // otherwise it is random so don't
     if (scenario) {
       options.scenario = scenario
     }
     try {
       var gameCodes = await ApiService.post(url, params, options)
     } catch (err) {
       console.log(err)
     }

     return gameCodes
   }

   /**
    * Join a session
    *
    * Returns the player session details
    */
   joinSession = async function (playerCode, playerName) {
     this.initialise()

     this.phase = 'setup'

     var url = '/api/v1/session/join'

     var params = {
       code: playerCode,
       name: playerName || null
     }

     var options = {}

     try {
       var playerSession = await ApiService.post(url, params, options)
     } catch (err) {
       console.log(err)
     }

     this.playerSession = playerSession

     this.waitForAllPlayers()

     //  If Observer then display everything
     if (this.playerSession.player === 'observer') {
       this.systemOverride = true
     }

     return this.playerSession
   }

   /**
    * Get session status
    *
    * Returns the current session status
    */
   getSessionStatus = async function () {
     var url = '/api/v1/session/status'

     if (!this.playerSession || !this.playerSession.token) {
       return
     }
     var params = {
       token: this.playerSession.token,
       r: Math.floor((Math.random() * 9999) + 1000) // random number to clear the cache
     }

     var options = {}

     try {
       var sessionStatus = await ApiService.get(url, params, options)
     } catch (err) {
       console.log(err)
     }

     this.sessionStatus = sessionStatus

     this.primaryPlayer = this.playerSession.player === this.sessionStatus.primaryPlayer

     this.vulnerability = this.sessionStatus.vulnerability

     this.messages = await this.getSystemMessages()

     if (this.sessionStatus.comments && this.sessionStatus.comments.length) {
       if (this.firstRead) {
         this.firstRead = false
         this.unreadMessages = 0
         this.readMessages = this.sessionStatus.comments.length
         // this.readMessages = this.messages.length
       } else {
         this.unreadMessages = this.sessionStatus.comments.length - this.readMessages
         //  this.unreadMessages = this.messages.length - this.readMessages
       }
     }

     this.phase = this.sessionStatus.phase

     this.getAllPlayers()

     //  Check and set if a game has been claimed
     this.claimRequestedBy = this.sessionStatus.claimRequestedBy

     //  setTimeout(() => {
     //    this.claimRequestedBy = 'north'
     //  }, 5000)

     // Set claimGameRequested if our opponent has claimed the game
     if (((this.playerSession.player === 'north' || this.playerSession.player === 'south') && (this.claimRequestedBy === 'east' || this.claimRequestedBy === 'west')) ||
     ((this.playerSession.player === 'east' || this.playerSession.player === 'west') && (this.claimRequestedBy === 'south' || this.claimRequestedBy === 'north'))) {
       this.claimGameRequested = true
     } else {
       this.claimGameRequested = false
     }

     if (this.checkForUndoApproval()) {
       console.log('????')
     }

     if (this.checkForClaimApproval()) {
       console.log('????')
     }

     if (this.phase === 'auction' && !this.allPlayersJoined) {
       // phase has just changed to auction so all players are now in
       this.lastPhase = 'auction'

       this.allPlayersJoined = true
       this.dealHands(1)
     } else if (this.phase === 'auction') {
       // general bidding round
       this.lastPhase = 'auction'

       //  this.rotatePlayers()

       //  Move bids to app format
       var bidRoundCount
       for (var i = 0; i < this.sessionStatus.bids.length; i++) {
         // Initialise bidArray
         var bidArray = {
           bidValue: parseInt(this.sessionStatus.bids[i].value ? this.sessionStatus.bids[i].value : 0),
           bidSuit: this.sessionStatus.bids[i].suit === 'notrump' ? 'nt' : this.sessionStatus.bids[i].suit,
           pass: this.sessionStatus.bids[i].pass,
           double: this.sessionStatus.bids[i].double,
           redouble: this.sessionStatus.bids[i].redouble
         }

         bidRoundCount = Math.floor(i / 4)

         this.roundBids[this.sessionStatus.bids[i].player].show = true
         this.roundBids[this.sessionStatus.bids[i].player].bids[bidRoundCount] = bidArray
       }
       this.bidTurn = this.sessionStatus.turn
     }
     //  Only first time then changes to this.sessionStatus.turn ?

     //  Check for end game
     if (this.lastPhase === 'play' && this.phase === 'results') {
       // phase has changed to results, so game just finished
       this.lastPhase = 'results'

       // Need to redeal to remove last card from hand
       this.dealHands(1)
       this.endGame()
     } else if (this.lastPhase === 'auction' && this.phase === 'play') {
       //  First play after auction has finished
       if (this.displayBidHistory === false) {
         this.displayBidHistory = true
       }

       this.lastPhase = 'play'
       this.allPlayersJoined = true

       if (!this.handDealt) {
         await this.initDeal(1)
         this.handDealt = true
       }

       this.turn = this.sessionStatus.declarer
       this.declarer = this.sessionStatus.declarer

       this.bid.level = this.sessionStatus.winningBidValue
       this.bid.denomination = this.sessionStatus.winningBidSuit === 'notrump' ? 'nt' : this.sessionStatus.winningBidSuit

       this.turn = this.sessionStatus.turn
       this.turnNumber = 1
       this.trickNumber = 1 // Trick number we are currently playing

       this.tricksWonByPartners.northsouth = 0
       this.tricksWonByPartners.eastwest = 0

       this.setupHand(this.sessionStatus.declarer)
     } else if (this.phase === 'play') {
       // Normal play
       this.lastPhase = 'play'
       this.allPlayersJoined = true

       if (!this.handDealt) {
         await this.initDeal(1)
         this.handDealt = true
       } else {
         this.dealHands(1)
       }

       if (this.sessionStatus.plays) {
         // Rotate table cards to correct positions
         var cardsPlayed = this.sessionStatus.plays
         var tricksPlayed = Math.floor(cardsPlayed.length / 4)

         // Add cards to table in case someone has re-logged in to game
         var tableCards = cardsPlayed.slice(tricksPlayed * 4)

         // If no tableCards we are on a new trick
         // and last card does not get displayed
         // so keep displaying last trick
         // until a new trick is started
         if (tableCards.length === 0 && !this.tableClear) {
           tableCards = cardsPlayed.slice((tricksPlayed - 1) * 4)
         }

         const seatPosition = {
           0: 'south',
           1: 'west',
           2: 'north',
           3: 'east'
         }

         for (var j = 0; j < tableCards.length; j++) {
           var playerKey = Object.keys(this.seats).find(key => this.seats[key] === tableCards[j].player)
           var player = seatPosition[playerKey]

           this.table[player].suit = tableCards[j].suit.substring(0, 1).toUpperCase()
           this.table[player].rank = tableCards[j].rank
         }

         this.declarer = this.sessionStatus.declarer

         this.bid.level = this.sessionStatus.winningBidValue
         this.bid.denomination = this.sessionStatus.winningBidSuit === 'notrump' ? 'nt' : this.sessionStatus.winningBidSuit

         this.turn = this.sessionStatus.turn
         this.turnNumber = cardsPlayed.length % 4
         this.trickNumber = tricksPlayed + 1 // Trick number we are currently playing

         this.tricksWonByPartners.northsouth = this.sessionStatus.NSTricks
         this.tricksWonByPartners.eastwest = this.sessionStatus.EWTricks
         this.setupHand(this.sessionStatus.declarer)

         // Check if timed out
         if (this.isTimedOut()) {
           this.playerTimedOut = true
         }

         // Check if partner has timed out
         if (this.isPartnerTimedOut() && !this.continueWaitingForPartnerFlag) {
           this.partnerTimedOut = true
         }

         if (cardsPlayed && cardsPlayed.length % 4 === 0) {
           //  A trick is complete
           //  If errorMessageSeen is false then the user has not
           //  seen and cleared the trick won message so display it
           //  Also need to ensure one trick has been played
           // Comment out for now
           //  if (!this.errorMessageSeen && cardsPlayed.length > 3) {
           //  this.error = true

           //  var cardsPlayedThisTrick = this.sessionStatus.plays.slice(-4)

           //  var tricksPlayedMessage = []
           //  tricksPlayedMessage.push(<Text>{this.sessionStatus[`name${this.capitalizeFirstLetter(this.sessionStatus.turn)}`]}({this.capitalizeFirstLetter(this.sessionStatus.turn)}) has won the trick</Text>)
           //  tricksPlayedMessage.push(<Text>Cards played were: </Text>)
           //  cardsPlayedThisTrick.forEach(function (play, index) {
           //    tricksPlayedMessage.push(<Text>{this.sessionStatus[`name${this.capitalizeFirstLetter(play.player)}`]}({this.capitalizeFirstLetter(play.player)}) played {play.rank} {this.capitalizeFirstLetter(play.suit)}</Text>)
           //  }, this)

           //  this.errorMessage = tricksPlayedMessage
           //  }

           // If all players have played, clear the table
           // but only if there's something on the table
           if (this.table.north.suit || this.table.east.suit || this.table.south.suit || this.table.west.suit) {
             setTimeout(() => {
               this.table = {
                 north: {
                   rank: '',
                   suit: ''
                 },
                 east: {
                   rank: '',
                   suit: ''
                 },
                 south: {
                   rank: '',
                   suit: ''
                 },
                 west: {
                   rank: '',
                   suit: ''
                 }
               }
               this.tableClear = true
             },
             5000)
           }
         }
       }
     } else if (this.phase === 'results') {
       this.gameOver = true
     } else if (this.phase === 'setup') {
       this.hands.south = 'AKQJT98765432...'
       this.hands.west = '.AKQJT98765432..'
       this.hands.north = '..AKQJT98765432.'
       this.hands.east = '...AKQJT98765432'
     }
     return this.sessionStatus
   }

   /**
    * Wait for all players
    *
    * Polls the session status checking
    * for the phase being 'auction'
    * which means all players have joined
    */
   waitForAllPlayers = function () {
     this.waitForAllPlayersInterval = setInterval(this.getSessionStatus.bind(this), 3000)
   }

   /**
    * Clear any error
    *
    */
   clearError = function () {
     this.error = false
     this.errorMessage = ''
     this.errorMessageSeen = true
   }

   /**
    * Capitalize first letter of string
    *
    */
   capitalizeFirstLetter (string) {
     return string.charAt(0).toUpperCase() + string.slice(1)
   }

   /**
    * Get all players
    */
   getAllPlayers () {
     this.allPlayers = {
       north: this.sessionStatus.nameNorth,
       east: this.sessionStatus.nameEast,
       south: this.sessionStatus.nameSouth,
       west: this.sessionStatus.nameWest
     }
   }

   /**
    * Clear player timeout
    */
   clearPlayerTimeout = function () {
     this.playerTimedOut = false
     this.allowTimeout = false

     //  this.playerTimeout = setTimeout(() => {
     //    this.playerTimedOut = true
     //  },
     //  30000)
   }

   /**
    * isPartnerTimedOut
    *
    * If your partner has timed out
    * then you can allow them to
    * be taken over by a bot
    */
    isPartnerTimedOut = function () {
      var myPartner = this.getPartner(this.playerSession.player)

      if (this.sessionStatus.turn === myPartner && this.sessionStatus.hasTimedOut && !this.continueWaitingForPartnerFlag) {
        return true
      } else {
        return false
      }
    }

   /**
    * getPartner
    *
    * Returns name of your partner
    */
   getPartner = function (player) {
     var partnerIndex = {
       west: 'east',
       north: 'south',
       east: 'west',
       south: 'north'
     }

     return partnerIndex[player]
   }

   /**
    * isTimedOut
    *
    * If it's your turn and you are not the dummy hand
    * or it is the dummy turn and you are the dummy's partner
    * check if timed out
    */
   isTimedOut = function () {
     if (((this.isMyTurn() && this.isNotDummy()) ||
    this.isDummyTurn()) &&
    !this.playerTimedOut &&
    this.allowTimeout &&
    this.sessionStatus.hasTimeoutWarning) {
       return true
     } else {
       return false
     }
   }

   /**
    * isMyTurn
    *
    * Determines if it is your turn
    */
   isMyTurn = function () {
     if (this.sessionStatus.turn === this.playerSession.player) {
       return true
     }
   }

   /**
    * isNotDummy
    *
    * Determines if you are not the dummy hand
    */
   isNotDummy = function () {
     if (this.sessionStatus.dummy !== this.playerSession.player) {
       return true
     }
   }

   /**
    * isDummyTurn
    *
    * Determines if it is dummy's turn
    * and current user is their partner
    */
   isDummyTurn = function () {
     // Current player is the declarer AND current turn is dummy
     if (this.sessionStatus.declarer === this.playerSession.player && this.sessionStatus.turn === this.sessionStatus.dummy) {
       return true
     }
   }

   /**
    * replacePartnerWithBot
    *
    * Turns the partner of this player into a bot
    *
    */
   replacePartnerWithBot = async function () {
     clearTimeout(this.partnerTimer)
     this.continueWaitingForPartnerFlag = false

     const botStatus = await this.makeBot(this.getPartner(this.playerSession.player))

     this.partnerTimedOut = false

     if (botStatus.success !== true) {
       this.continueWaitingForPartner()
     }
   }

   /**
    * continueWaitingForPartner
    *
    * Give the partner some more time to play
    */
   continueWaitingForPartner = function () {
     clearTimeout(this.partnerTimer)
     this.partnerTimedOut = false
     this.continueWaitingForPartnerFlag = true

     this.partnerTimer = setTimeout(function () {
       this.continueWaitingForPartnerFlag = false
     }.bind(this),
     60000)
   }

   /**
    * sendMessage
    *
    * Post a message to other players
    */
   sendMessage = async function (player, message) {
     var url = '/api/v1/session/comment'

     var params = {
       token: this.playerSession.token,
       to: player,
       message: message
     }

     var options = {}

     try {
       var messageStatus = await ApiService.post(url, params, options)
     } catch (err) {
       console.log(err)
     }

     return messageStatus
   }

   /**
    * makeBot
    *
    * Turn a player into a bot
    */
   makeBot = async function (partner) {
     var url = '/api/v1/session/makebot'

     var params = {
       token: this.playerSession.token,
       target: partner
     }

     var options = {}

     try {
       var botStatus = await ApiService.post(url, params, options)
     } catch (err) {
       console.log(err)
     }

     return botStatus
   }

   /**
    * getSystemMessages
    *
    * Format the messages for display
    */
   getSystemMessages = async function () {
     // Reverse the comments array so they are in
     // reverse time order and only return ten
     if (this.sessionStatus.comments && this.sessionStatus.comments.length) {
       var reversedArray = this.sessionStatus.comments.slice(0).reverse().map(function (message) {
         return '<b>' + message.from + '</b>' + ' to ' + '<b>' + this.capitalizeFirstLetter(message.to) + '</b>' + ': ' + message.message
       }, this)

       return reversedArray.slice(0, 10)
     } else {
       return null
     }
   }

   /**
    * clearUnreadMessages
    *
    * Set the count of unread messages to zero
    */
   clearUnreadMessages = function () {
     this.unreadMessages = 0
     this.readMessages = this.sessionStatus.comments.length
   }

   /**
    * clearDisplayBidHistory
    *
    * Set flag to show that the bid history screen
    * has been seen after the auction is complete
    */
   clearDisplayBidHistory = function () {
     this.displayBidHistorySeen = true
   }

   /**
    * rotatePlayers
    */
    rotatePlayers = function () {
      //
      // The hands will show the physical position
      // of the player eg. this.hands.north will
      // be the hand at the top of the table
      //
      // We need to rotate the hands to fit correctly
      // eg. if we are south then no change
      //  If we are east we need to rotate the hands
      // by one place clockwise (or 3 anti-clockwise)
      // so east appears as this.hands.south
      //
      var seats = [
        'south',
        'west',
        'north',
        'east'
      ]

      var rotateSeatOrder = {
        south: 0,
        west: 1,
        north: 2,
        east: 3
      }

      this.seats = this.arrayRotate(seats, rotateSeatOrder[this.playerSession.player])

      // Re-arrange the player names so this player appears in the south position
      this.playerName = this.seats

      // Add player name to seat position
      // If bot then add name as bot
      for (var i = 0; i < this.playerName.length; i++) {
        var directionSymbol = this.playerName[i].charAt(0).toUpperCase()
        var uppercasePlayer = this.playerName[i].charAt(0).toUpperCase() + this.playerName[i].slice(1)

        if (this.sessionStatus[`name${uppercasePlayer}`]) {
          this.playerRealName[i] = `${directionSymbol} | ` + this.sessionStatus[`name${uppercasePlayer}`]
        }
        if (this.sessionStatus[`${this.playerName[i]}Type`] === 'bot') {
          this.playerRealName[i] = `${directionSymbol} | BOT`
        }
      }
    }

    /**
     * replayScenario
     *
     * Replay same game with same players
     */
    replayScenario = async function () {
      if (this.playerSession.player === this.sessionStatus.primaryPlayer) {
        clearInterval(this.waitForAllPlayersInterval)
        this.initialise()

        this.phase = 'setup'

        var url = '/api/v1/session/replay'

        var params = {
          token: this.playerSession.token
        }

        var options = {}

        try {
          var playerCodes = await ApiService.post(url, params, options)
        } catch (err) {
          console.log(err)
        }

        console.log(playerCodes)
        // NOTE - playerCodes returns northCode etc only to remind of the codes
        this.joinSession(playerCodes[`${this.playerSession.player}Code`], this.playerSession.name)
      } else {
        this.joinSession()
      }
    }

    /**
     * UndoRequest
     *
     * Request to undo turn
     */
    undoRequest = async function () {
      var url = '/api/v1/session/undo/request'

      var params = {
        token: this.playerSession.token
      }

      var options = {}

      try {
        var requestStatus = await ApiService.post(url, params, options)
      } catch (err) {
        console.log(err)
      }

      console.log(requestStatus)
    }

    /**
     * checkForUndoApproval
     *
     * Check if we need to approve an undo request
     */
    checkForUndoApproval = function () {
      const undoRequestedBy = this.sessionStatus.undoRequestedBy
      const currentPlayer = this.playerSession.player

      if (!undoRequestedBy && undoRequestedBy !== 'no_one') {
        if (((currentPlayer === 'east' || currentPlayer === 'west') && (undoRequestedBy === 'north' || undoRequestedBy === 'south')) ||
           ((currentPlayer === 'south' || currentPlayer === 'north') && (undoRequestedBy === 'east' || undoRequestedBy === 'west'))) {
          this.undoRequestedBy = this.capitalizeFirstLetter(undoRequestedBy)
          this.approveUndoRequest = true
          console.log('we should approve')
        } else {
          this.undoRequestedBy = ''
          this.approveUndoRequest = false
        }
      }
    }

    /**
     * approveUndo
     *
     * Approve an Undo request
     */
    approveUndo = async function () {
      var url = '/api/v1/session/undo/approve'

      var params = {
        token: this.playerSession.token
      }

      var options = {}

      try {
        var requestStatus = await ApiService.post(url, params, options)
      } catch (err) {
        console.log(err)
      }

      console.log(requestStatus)
    }

    /**
     * denyUndo
     *
     * Deny an Undo request
     */
    denyUndo = async function () {
      var url = '/api/v1/session/undo/deny'

      var params = {
        token: this.playerSession.token
      }

      var options = {}

      try {
        var requestStatus = await ApiService.post(url, params, options)
      } catch (err) {
        console.log(err)
      }

      console.log(requestStatus)
    }

    /**
     * ClaimGame
     *
     * Request to claim the game
     */
    claimGame = async function () {
      var url = '/api/v1/session/claim/request'

      var params = {
        token: this.playerSession.token
      }

      var options = {}

      try {
        var requestStatus = await ApiService.post(url, params, options)
      } catch (err) {
        console.log(err)
      }

      console.log(requestStatus)
    }

    /**
     * checkForClaimApproval
     *
     * Check if we need to approve a claim request
     */
    checkForClaimApproval = function () {
      const claimRequestedBy = this.sessionStatus.claimRequestedBy
      const currentPlayer = this.playerSession.player
      const northApproval = this.sessionStatus.claimApprovedNorth
      const eastApproval = this.sessionStatus.claimApprovedEast
      const southApproval = this.sessionStatus.claimApprovedSouth
      const westApproval = this.sessionStatus.claimApprovedWest

      if (claimRequestedBy && claimRequestedBy !== 'no_one') {
        // Check if this player needs to approve/deny request
        if (((currentPlayer === 'east' || currentPlayer === 'west') && (claimRequestedBy === 'north' || claimRequestedBy === 'south')) ||
           ((currentPlayer === 'south' || currentPlayer === 'north') && (claimRequestedBy === 'east' || claimRequestedBy === 'west'))) {
          // Set cards to visible
          this.makeHandsVisibleAfterClaim(currentPlayer, claimRequestedBy)

          this.claimRequestedBy = this.capitalizeFirstLetter(claimRequestedBy)
          this.approveClaimRequest = true
          console.log('we should approve')
        } else {
          this.claimRequestedBy = ''
          this.approveClaimRequest = false
        }

        // If opposition have both approved then make everyone a robot
        if (((claimRequestedBy === 'north' || claimRequestedBy === 'south') && (eastApproval && westApproval)) ||
           ((claimRequestedBy === 'east' || claimRequestedBy === 'west') && (northApproval && southApproval))) {
          this.makeBot('north')
          this.makeBot('east')
          this.makeBot('south')
          this.makeBot('west')
        }
      }
    }

    /**
     * approveClaim
     *
     * Approve a Claim request
     */
    approveClaim = async function () {
      var url = '/api/v1/session/claim/approve'

      var params = {
        token: this.playerSession.token
      }

      var options = {}

      try {
        var requestStatus = await ApiService.post(url, params, options)
      } catch (err) {
        console.log(err)
      }

      console.log(requestStatus)
    }

    /**
     * denyClaim
     *
     * Deny a Claim request
     */
    denyClaim = async function () {
      var url = '/api/v1/session/claim/deny'

      var params = {
        token: this.playerSession.token
      }

      var options = {}

      try {
        var requestStatus = await ApiService.post(url, params, options)
      } catch (err) {
        console.log(err)
      }

      console.log(requestStatus)
    }
}

export default new GameStore()
